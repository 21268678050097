.modal-card.extra-padding {
 padding-top: 3em;
 padding-bottom: 3em;
}

.progress-bar {
    min-height: 3em;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

